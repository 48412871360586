export default class RouteBuilder<T extends string = string> {
  private routes = new Map<T, Route<T>>();

  add(name: T, route: string) {
    this.routes.set(name, new Route(name, route));
  }

  get(name: T, opts?: Record<string, unknown>): string {
    if (!this.routes.has(name)) {
      throw new Error(`No route defined for '${name}'`);
    }
    const route = this.routes.get(name);
    if (route) {
      if (opts) {
        const { params, ...urlParams } = opts;
        const parts = route.path.split('/');
        const url = parts.map(part => {
          if (!part.startsWith(':')) {
            return part;
          }
          const param = urlParams[part.substr(1)];
          if (!param) {
            throw new Error(`Missing url param '${part.substr(1)}'`);
          }
          return param;
        });
        return url.join('/');
      }
      return route.path;
    }
    return '';
  }
}

class Route<T extends string = string> {
  constructor(public name: T, public path: string) {}

  get(opts?: Record<T, unknown>): string {
    if (!opts) {
      return this.path;
    }

    return this.path;
  }
}
