import { handleActions, createAction } from 'redux-actions';

export interface EventState {
  events: ReadonlyArray<DJEvent>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  event?: DJEvent;
}

const defaultState: EventState = {
  event: undefined,
  events: []
};

export const saveEvent = createAction('SAVE_EVENT');
export const saveEvents = createAction('SAVE_EVENTS');
export default handleActions(
  {
    LOGOUT: () => defaultState,
    SAVE_EVENT: (state, action) => ({
      ...state,
      event: action.payload || defaultState.event
    }),
    SAVE_EVENTS: (state, action) => ({
      ...state,
      events: action.payload || defaultState.events
    })
  },
  defaultState
);
