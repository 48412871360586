import { createStore, applyMiddleware, compose, Store } from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import reducers from './reducers';
import runSagas from './sagas';
import { composeWithDevTools } from 'redux-devtools-extension';
import { History } from 'history';

const sagaMiddleware = createSagaMiddleware();
const configureStore = (history: History): { store: Store; persistor: Persistor } => {
  const reduxMiddleware = [sagaMiddleware, routerMiddleware(history)];

  /* eslint-ignore-next-line */
  const composeEnhancers: any = process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

  const store = createStore(reducers, undefined, composeEnhancers(applyMiddleware(...reduxMiddleware)));

  const persistor = persistStore(store);

  runSagas(sagaMiddleware);

  return { persistor, store };
};

export default configureStore;
