import { handleActions, createAction } from 'redux-actions';

const defaultState: SystemState = {
  activeEvent: undefined,
  allowRegistration: true,
  allowScheduling: true,
  isLoading: {},
};

export const isLoading = createAction('IS_LOADING');
export const doneLoading = createAction('DONE_LOADING');
export const persistConfiguration = createAction('PERSIST_CONFIGURATION');

export interface SystemState extends SystemConfig {
  readonly isLoading: any;
}

export default handleActions({
  IS_LOADING: (state, action) => ({
    ...state,
    isLoading: {
      ...state.isLoading,
      [action.payload]: true
    }
  }),
  DONE_LOADING: (state, action) => {
    if (action.payload && state.isLoading[action.payload]) {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload]: undefined
        }
      };
    }
    return state;
  },
  PERSIST_CONFIGURATION: (state, action) => ({
    ...state,
    ...action.payload
  }),
  LOGOUT: () => defaultState
}, defaultState);
