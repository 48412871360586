import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';

import * as firebaseui from 'firebaseui';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
export const db = firebase.database();
export const auth = firebase.auth();
export const functions = firebase.functions();

export const ui = new firebaseui.auth.AuthUI(auth);

export const uiConfig: firebaseui.auth.Config = {
  signInOptions: [
    // {
    //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //   signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
    // },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
      requireDisplayName: false
    }
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID
  ],
  signInSuccessUrl: '/'
};

export const getCurrentUser = (): firebase.User | null => auth.currentUser;
export async function getCurrentUserToken(forceRefresh: boolean = false): Promise<string | undefined> {
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken(forceRefresh);
    return token;
  }
  return undefined;
}

const getProfile = state => state.user.profile;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasRole = (state: any, roles: Array<string>): void => {
  if (state && state.user) {
    const profile = getProfile(state);
    if (profile && profile.roles) {
      if (roles.filter(role => profile.roles[role]).length > 0) {
        return;
      }
    }
  }
  throw new Error('Unauthorized');
};

export const storageKey = 'AOD_MTD_STORAGE_KEY';

export const isAuthenticated = (): boolean => !auth.currentUser || !!localStorage.getItem(storageKey);

export default firebase;
