import * as React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MaterialGrid, { GridProps as MaterialGridProps } from '@material-ui/core/Grid';

import { Box, BoxProps } from './Box';

const useStyles = makeStyles(theme => ({
  grid: (props: GridProps) => ({
    alignContent: props.alignContent,
    alignSelf: props.alignSelf,
    justifySelf: props.justifySelf
  })
}));

export interface GridProps
  extends Omit<BoxProps, 'alignItems' | 'alignContent'>,
    Pick<
      MaterialGridProps,
      | 'alignItems'
      | 'alignContent'
      | 'container'
      | 'direction'
      | 'item'
      | 'justify'
      | 'spacing'
      | 'wrap'
      | 'xs'
      | 'sm'
      | 'md'
      | 'lg'
      | 'xl'
      | 'zeroMinWidth'
    > {}

export const Grid: React.FC<GridProps> = React.forwardRef((props: GridProps, ref: React.Ref<any>) => {
  const { className, children, ...rest } = props;
  const classes = useStyles(props);
  return (
    <Box className={clsx(classes.grid, className)} component={MaterialGrid} {...rest}>
      {children}
    </Box>
  );
  // return <MaterialGrid {...rest}>{children}</MaterialGrid>;
});

Grid.displayName = 'Grid';

const flexBreakStyles = makeStyles({
  break: {
    flexBasis: '100%',
    width: 0
  }
});
export const FlexBreak: React.FC<Record<string, unknown>> = React.memo(() => {
  const classes = flexBreakStyles();
  return <div className={classes.break} />;
});
