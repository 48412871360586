import _ from 'lodash';
import * as React from 'react';
import { Dispatch } from 'redux';
import { auth } from 'store/firebase';
import { connect } from 'react-redux';
import { logout, getProfile } from 'store/sagas/user';
import { saveUserAuth } from 'store/reducers/user';
import { Loading } from 'components/pages/Loading';
import { getSystemVersion } from 'store/sagas/system';

const mapState = state => ({});

const mapDispatch = (dispatch: Dispatch) => ({
  onGetProfile: () => dispatch(getProfile()),
  onGetSystemVersion: () => dispatch(getSystemVersion()),
  onLogout: () => dispatch(logout()),
  onSaveAuth: data => dispatch(saveUserAuth(data))
});

export interface ConnectedFirebaseUserProviderProps {
  readonly onGetProfile: () => void;
  readonly onSaveAuth: (data: any) => void;
  readonly onLogout: () => void;
  readonly onGetSystemVersion: () => void;
}

const ConnectedFirebaseUserProvider: React.FC<ConnectedFirebaseUserProviderProps> = props => {
  const { onGetSystemVersion, onGetProfile, onLogout, children, onSaveAuth } = props;
  const [currentUser, setCurrentUser] = React.useState<firebase.User | null | undefined>(undefined);

  React.useEffect(() => {
    if (currentUser) {
      onSaveAuth({ email: currentUser.email, uid: currentUser.uid });
      onGetProfile();
    } else if (currentUser === null) {
      onLogout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  React.useEffect(() => {
    onGetSystemVersion();
    auth.onIdTokenChanged(function(user: firebase.User | null) {
      if (!_.isEqual(user, currentUser)) {
        setCurrentUser(user);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentUser !== undefined) {
    return <>{children}</>;
  }
  return <Loading />;
};

export default connect(mapState, mapDispatch)(ConnectedFirebaseUserProvider);
