import { takeEvery, put, call } from 'redux-saga/effects';
import { Action } from 'redux';

import { isLoading, doneLoading } from 'store/reducers/config';
import { functions } from '../firebase';
import { persistDJAvailability } from 'store/reducers/report';
// getCurrentDJAvailabilityReport
// getEventDJVoteReport

export const GET_DJ_AVAILABILITY_REPORT = 'report/GET_DJ_AVAILABILITY_REPORT';
export interface GetDJAvailabilityReportAction extends Action<typeof GET_DJ_AVAILABILITY_REPORT> {}
export const getDJAvailabilityReport = (): GetDJAvailabilityReportAction => ({
  type: GET_DJ_AVAILABILITY_REPORT
});

function* getDJAvailabilityReportWorker(action: GetDJAvailabilityReportAction) {
  try {
    yield put(isLoading(action.type));
    const getCurrentDJAvailabilityReportFn = functions.httpsCallable('getCurrentDJAvailabilityReport');
    const response = yield call(getCurrentDJAvailabilityReportFn);

    const { report } = response.data;
    yield put(persistDJAvailability(report));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* watchGetDJAvailabilityReport() {
  yield takeEvery(GET_DJ_AVAILABILITY_REPORT, getDJAvailabilityReportWorker);
}
