import 'firebaseui/dist/firebaseui.css';
import React from 'react';
import MomentUtils from '@date-io/moment';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { createBrowserHistory } from 'history';
import configureStore from './store';
import Router from './router/Router';
import { Loading } from './components/pages/Loading';
import ConnectedFirebaseUserProvider from 'containers/firebase/ConnectedFirebaseUserProvider';

const history = createBrowserHistory();
const { store, persistor } = configureStore(history);

const App: React.FC<any> = () => (
  <>
    <CssBaseline />
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<Loading />}>
          <React.Suspense fallback={<Loading />}>
            <ConnectedFirebaseUserProvider>
              <Router history={history} />
            </ConnectedFirebaseUserProvider>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </MuiPickersUtilsProvider>
  </>
);

export default App;
