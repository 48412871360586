import _ from 'lodash';
import * as React from 'react';
import {
  AlignContentProps,
  FontFamilyProps,
  FontSizeProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  SpaceProps
} from 'styled-system';

import { Typography, makeStyles, Theme } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { getPaletteColor } from 'assets/styles';
import clsx from 'clsx';
import { ColorThemeVariant } from 'assets/color';

export interface TextProps
  extends Omit<TypographyProps, 'color'>,
    AlignContentProps,
    FontFamilyProps,
    FontSizeProps,
    FontWeightProps,
    LetterSpacingProps,
    LineHeightProps,
    SpaceProps {
  readonly color?: ColorThemeVariant | 'textPrimary' | 'textSecondary' | 'inherit' | 'disabled' | 'hint';
  readonly loading?: boolean;
  readonly bold?: boolean;
  readonly caps?: boolean;
  readonly italic?: boolean;
  readonly semibold?: boolean;
  readonly lightbold?: boolean;
  readonly strikethrough?: boolean;
  readonly underline?: boolean;
  readonly component?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
}

/**
 * Helper function for removing TextStyleProps from a components prop list. Useful when
 * you grab a components remaining props and want to pass along whats left, excluding
 * any text style props.
 *
 * This can be useful to prevent unexpected text style props getting passed to DOM elements.
 * If included, prop names like 'lineHeight' will throw "Warning: React does not recognize
 * the 'lineHeight' prop on a DOM element." error message.
 *
 *
 * Usage:
 *   const { propA, ...remainingProps } = props;
 *   return <SubComponent {...removeTextStyleProps(remainingProps)} />
 */
export function removeTextStyleProps<T>(props: Partial<T> = {}): Pick<Partial<T>, Exclude<keyof T, TextProps>> {
  return _.omit(props || {}, [
    // ColorProps
    // 'color',
    'bg',
    // DisplayProps
    'display',
    // FontFamilyProps
    'fontFamily',
    // FontSizeProps
    'fontSize',
    // FontWeightProps
    'fontWeight',
    // LetterSpacingProps
    'letterSpacing',
    // LineHeightProps
    'lineHeight',
    // TextAlignProps
    'textAlign',
    // Custom TextStylesProps
    'bold',
    'caps',
    'italic',
    'semibold',
    'lightbold',
    'strikethrough',
    'underline'
  ]);
}

const useTextStyles = makeStyles(theme => ({
  text: (props: TextProps) => {
    const css: any = {
      color: getTextColor(theme, props.color)
    };

    if (props.bold) {
      css.fontWeight = 'bold';
    }

    if (props.fontSize) {
      css.fontSize = props.fontSize;
    }

    if (props.underline) {
      css.textDecoration = 'underline';
    }

    if (props.italic) {
      css.fontStyle = 'italic';
    }

    if (props.caps) {
      css.textTransform = 'uppercase';
    }

    if (props.strikethrough) {
      css.textDecoration = 'line-through';
    }

    return css;
  }
}));

function getTextColor(theme: Theme, color?: TextProps['color']): string | undefined {
  if (color) {
    if (['textPrimary', 'textSecondary', 'inherit'].includes(color)) {
      switch (color) {
        case 'inherit':
          return color;
        case 'textPrimary':
          return theme.palette.text.primary;
        case 'textSecondary':
          return theme.palette.text.secondary;
        case 'hint':
          return theme.palette.text.hint;
        case 'disabled':
          return theme.palette.text.disabled;
        default:
          return theme.palette.text.primary;
      }
    } else {
      return getPaletteColor(theme, color as ColorThemeVariant);
    }
  }
  return theme.palette.text.primary;
}

const Text: React.FC<TextProps> = React.forwardRef((props, ref: React.Ref<any>) => {
  const { color, className, loading, children, ...rest } = removeTextStyleProps<TextProps>(props);
  const classes = useTextStyles(props);
  return (
    <Typography ref={ref} className={clsx(classes.text, className)} {...rest}>
      {loading ? <Skeleton variant="text" /> : children}
    </Typography>
  );
});

Text.displayName = 'Text';

Text.defaultProps = {
  color: 'textPrimary'
};

export const Span: React.FC<TextProps> = React.forwardRef(({ children, ...rest }, ref) => (
  <Text component="span" {...rest}>
    {children}
  </Text>
));

Span.displayName = 'Span';

Span.defaultProps = {
  color: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit'
};

export default Text;
