import * as React from 'react';

export interface ElevatedTileProps {
  readonly color?: 'gray' | 'red' | 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'indigo' | 'purple';
  readonly icon?: JSX.Element;
}

export const ElevatedTile: React.FC<any> = props => {
  const { icon, color, children, title } = props;
  return (
    <div className={`bg-${color}-100 border-b-4 border-${color}-600 rounded-lg shadow-lg p-5`}>
      <div className="flex flex-row items-center">
        <div className="flex-shrink pr-4">
          <div className={`rounded-full p-5 bg-${color}-600`}>{icon}</div>
        </div>
        <div className="flex-1 text-right md:text-center">
          {title && <h5 className="font-bold uppercase text-gray-600">{title}</h5>}
          <h3 className="font-bold text-3xl">{children}</h3>
        </div>
      </div>
    </div>
  );
};

ElevatedTile.defaultProps = {
  color: 'gray'
};
