import { createAction } from 'redux-actions';
import { takeEvery, put, call } from 'redux-saga/effects';
import { Action } from 'redux';

import { doneLoading, isLoading, persistAccounts } from '../reducers/config';
import { db } from '../firebase';

const ACCOUNTS_REF = 'accounts/';

const GET_ACCOUNTS = 'config/GET_ACCOUNTS';
export interface GetAccountsAction extends Action<typeof GET_ACCOUNTS> {}
export const getAccounts = createAction(GET_ACCOUNTS);
function* getAccountsListener(action: GetAccountsAction) {
  try {
    yield put(isLoading(action.type));

    const ref = db.ref(`${ACCOUNTS_REF}`);
    const accounts = yield call([ref, ref.once], 'value');
    yield put(persistAccounts(accounts.val()));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetAccounts() {
  yield takeEvery(GET_ACCOUNTS, getAccountsListener);
}

const SAVE_ACCOUNT_ID = 'config/SAVE_ACCOUNT_ID';
export interface EnrollAccountIdAction extends Action<typeof SAVE_ACCOUNT_ID> {
  readonly payload: {
    readonly accountId: string;
    readonly role?: string;
  };
}
export const enrollAccountId = (accountId: string, role?: string): EnrollAccountIdAction => ({
  payload: {
    accountId,
    role
  },
  type: SAVE_ACCOUNT_ID
});
function* saveAccountIdListener(action: EnrollAccountIdAction) {
  try {
    yield put(isLoading(action.type));

    const { accountId, role } = action.payload;
    const ref = db.ref(ACCOUNTS_REF).child(accountId);
    if (role) {
      yield call([ref, ref.set], role);
    } else {
      yield call([ref, ref.remove]);
    }
    yield put(getAccounts());
    window.alert(`${accountId} was added to preregistration`);
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchSaveAccountId() {
  yield takeEvery(SAVE_ACCOUNT_ID, saveAccountIdListener);
}
