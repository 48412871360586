import RouteBuilder from './RouteBuilder';

export type RouteKey =
  | 'login'
  | 'register'
  | 'userProfile'
  | 'user'
  | 'users'
  | 'events'
  | 'eventReview'
  | 'event'
  | 'userSchedule'
  | 'userRegistration'
  | 'faq'
  | 'home';

const routeBuilder = new RouteBuilder<RouteKey>();

routeBuilder.add('login', '/login');
routeBuilder.add('register', '/register');
routeBuilder.add('userProfile', '/profile');
routeBuilder.add('users', '/users');
routeBuilder.add('user', '/users/:userId');

// Admin/Staff Routes
routeBuilder.add('home', '/');
routeBuilder.add('userSchedule', '/admin/schedule');
routeBuilder.add('userRegistration', '/admin/registration');
routeBuilder.add('faq', '/admin/faq');

routeBuilder.add('event', '/events/:eventId');
routeBuilder.add('events', '/events');
routeBuilder.add('eventReview', '/events/:eventId/review');

export default routeBuilder;
