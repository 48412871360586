import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import Color from 'color';

import { Theme } from '@material-ui/core/styles';

import { ColorThemeVariant } from './color';

// The color management strategy used here is to give colors a unique
// name rather than define the color by how or where they are used.
// This allows us to to make colors more widely available and allows us
// to communicate colors based on names.

// Color names do not have to be exact. You can use sites like
// http://chir.ag/projects/name-that-color/
// to find a color name. Try to keep them organized into similar
// color groups.

// Please list each group of colors in alphabetical order

// To decrease the chance of duplicated colors, please list colors inside
// each color group from darkest value (00xxxx) to lightest value (FFxxxx).
// When adding colors, it will then be easier to identify close matches.

export const Fonts = 'Roboto, sans-serif';
export { default as Colors } from './color';

export const NAVDRAWER_WIDTH = 240;
export const NAVBAR_HEIGHT = 96;

export function styleable<T = Record<string, unknown>>(
  Component: React.ComponentType<T>,
  filteredProps: Array<keyof T> = []
): any {
  const FilteredComponent = (props: T) => <Component {...(_.omit(props as any, filteredProps) as any)} />;
  FilteredComponent.displayName = Component.displayName;

  return styled(FilteredComponent);
}

// ! Passthrough Styled component libraries, can clean up later
export { css } from 'styled-components';
export default styled;

interface BackgroundGradientOpts {
  readonly degrees?: number;
  readonly direction?: {
    readonly to: number;
    readonly from: number;
  };
  readonly opacity?: number;
}

const defaultBackgroundGradientOpts: BackgroundGradientOpts = {
  degrees: 0,
  direction: {
    from: 0,
    to: 100
  },
  opacity: 1
};

export function generateGradient(
  color: string,
  color2: string,
  opts: BackgroundGradientOpts = defaultBackgroundGradientOpts
): string {
  const {
    direction = defaultBackgroundGradientOpts.direction,
    degrees = defaultBackgroundGradientOpts.degrees,
    opacity
  } = opts;
  if (opacity !== undefined) {
    return `linear-gradient(${degrees}deg, ${Color(color)
      .fade(opacity)
      .toString()} ${direction!.from}%, ${Color(color2)
      .fade(opacity)
      .toString()} ${direction!.to}%)`;
  }
  return `linear-gradient(${degrees}deg, ${color} ${direction!.from}%, ${color2} ${direction!.to}%)`;
}

export function getBackgroundGradient(theme: Theme, type?: ColorThemeVariant, opts?: BackgroundGradientOpts): string {
  switch (type) {
    case 'primaryLight':
      return generateGradient(
        theme.palette.primary.light,
        Color(theme.palette.primary.light)
          .lighten(0.2)
          .toString(),
        opts
      );
    case 'secondaryLight':
      return generateGradient(
        theme.palette.secondary.light,
        Color(theme.palette.secondary.light)
          .lighten(0.2)
          .toString(),
        opts
      );
    case 'warningLight':
      return generateGradient(
        theme.palette.warning.light,
        Color(theme.palette.warning.light)
          .lighten(0.2)
          .toString(),
        opts
      );
    case 'errorLight':
      return generateGradient(
        theme.palette.error.light,
        Color(theme.palette.error.light)
          .lighten(0.2)
          .toString(),
        opts
      );
    case 'successLight':
      return generateGradient(
        theme.palette.success.light,
        Color(theme.palette.success.light)
          .lighten(0.2)
          .toString(),
        opts
      );
    case 'primary':
      return generateGradient(theme.palette.primary.dark, theme.palette.primary.light, opts);
    case 'secondary':
      return generateGradient(theme.palette.secondary.dark, theme.palette.secondary.light, opts);
    case 'info':
      return generateGradient(theme.palette.info.dark, theme.palette.info.light, opts);
    case 'warning':
      return generateGradient(theme.palette.warning.dark, theme.palette.warning.light, opts);
    case 'error':
      return generateGradient(theme.palette.error.dark, theme.palette.error.light, opts);
    case 'success':
      return generateGradient(theme.palette.success.dark, theme.palette.success.light, opts);
    case 'white':
      return '#FFF';
    case 'mediumGray':
      return generateGradient(theme.palette.grey[700], theme.palette.grey[400], opts);
    case 'lightGray':
      return generateGradient(theme.palette.grey[200], theme.palette.grey[400], opts);
    case 'default':
      return generateGradient(theme.palette.grey[900], theme.palette.grey[700], opts);
    default:
      return generateGradient(theme.palette.grey[400], theme.palette.grey[300], opts);
  }
}

export function getPaletteColor(
  theme: Theme,
  type?: ColorThemeVariant,
  defaultColor = theme.palette.grey[400]
): string {
  switch (type) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'info':
      return theme.palette.info.main;
    case 'success':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'error':
      return theme.palette.error.main;
    case 'primaryLight':
      return theme.palette.primary.light;
    case 'secondaryLight':
      return theme.palette.secondary.light;
    case 'successLight':
      return theme.palette.success.light;
    case 'warningLight':
      return theme.palette.warning.light;
    case 'errorLight':
      return theme.palette.error.light;
    case 'infoLight':
      return theme.palette.info.light;
    case 'default':
      return theme.palette.grey[800];
    case 'mediumGray':
      return theme.palette.grey[400];
    case 'lightGray':
      return theme.palette.grey[200];
    case 'white':
      return '#FFF';
    case 'disabled':
      return theme.palette.text.disabled;
    default:
      return defaultColor;
  }
}
