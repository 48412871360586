import * as React from 'react';
import { Link } from 'react-router-dom';

export const PrimaryNav = props => {
  return (
    <ul className="list-reset flex flex-row md:flex-col py-0 md:py-3 px-1 md:px-2 text-center md:text-left">
      <li className="mr-3 flex-1">
        <Link
          to="/events"
          className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-pink-500"
        >
          <i className="fas fa-tasks pr-0 md:pr-3"></i>
          <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
            Events
          </span>
        </Link>
      </li>
      <li className="mr-3 flex-1">
        <Link
          to="#"
          className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-purple-500"
        >
          <i className="fa fa-envelope pr-0 md:pr-3"></i>
          <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
            Calendar
          </span>
        </Link>
      </li>
      <li className="mr-3 flex-1">
        <Link
          to="/users"
          className="block py-1 md:py-3 pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-blue-600"
        >
          <i className="fas fa-chart-area pr-0 md:pr-3 text-blue-600"></i>
          <span className="pb-1 md:pb-0 text-xs md:text-base text-white md:text-white block md:inline-block">
            Users
          </span>
        </Link>
      </li>
      <li className="mr-3 flex-1">
        <Link
          to="/profile"
          className="block py-1 md:py-3 pl-0 md:pl-1 align-middle text-white no-underline hover:text-white border-b-2 border-gray-800 hover:border-red-500"
        >
          <i className="fa fa-wallet pr-0 md:pr-3"></i>
          <span className="pb-1 md:pb-0 text-xs md:text-base text-gray-600 md:text-gray-400 block md:inline-block">
            Profile
          </span>
        </Link>
      </li>
    </ul>
  );
};
