/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAction } from 'redux-actions';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { doneLoading, isLoading } from '../reducers/config';
import { db, functions } from '../firebase';
import { saveEvents, saveEvent } from '../reducers/events';
import { Action } from 'redux';
import moment from 'moment';
import { replace } from 'react-router-redux';
import routeBuilder from 'router/route.service';

const EVENTS_REF = 'events';
const EVENT_MEMBERS_REF = 'event-members';

const CREATE_EVENT = 'CREATE_EVENT';
export interface CreateEventAction extends Action<typeof CREATE_EVENT> {
  readonly payload: {
    readonly event: DJEventFormValues;
  };
}
export const createEvent = (event: DJEventFormValues): CreateEventAction => ({
  payload: {
    event
  },
  type: CREATE_EVENT
});

function* createEventListener(action: CreateEventAction) {
  try {
    yield put(isLoading(action.type));
    const { event } = action.payload;
    const { start, end, date } = event;

    console.log('Event', { date, end, start });
    const dateMoment = moment(date);
    const startMoment = moment(start)
      .startOf('minute')
      .set({
        date: dateMoment.date(),
        month: dateMoment.month(),
        year: dateMoment.year()
      });
    const endMoment = moment(end)
      .startOf('minute')
      .set({
        date: dateMoment.date(),
        month: dateMoment.month(),
        year: dateMoment.year()
      });
    const formattedMoment = dateMoment.format('YYYY-MM-DD');

    const ref = db.ref(EVENTS_REF).child(formattedMoment);

    const newEvent = {
      date: formattedMoment,
      end: endMoment.format('YYYY-MM-DD[T]HH:mm:ss'),
      start: startMoment.format('YYYY-MM-DD[T]HH:mm:ss')
    };

    yield call([ref, ref.set], newEvent);
    yield put(getEvents());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchCreateEvent() {
  yield takeEvery(CREATE_EVENT, createEventListener);
}

const DELETE_EVENT = 'DELETE_EVENT';
export interface DeleteEventAction extends Action<typeof DELETE_EVENT> {
  readonly payload: {
    readonly eventId: string;
  };
}
export const deleteEvent = (event: DJEvent): DeleteEventAction => ({
  payload: {
    eventId: event.id
  },
  type: DELETE_EVENT
});

function* deleteEventListener(action: DeleteEventAction) {
  try {
    yield put(isLoading(action.type));
    const { eventId } = action.payload;
    const ref = db.ref(EVENTS_REF).child(eventId);
    const snap = yield call([ref, ref.once], 'value');
    if (snap.exists()) {
      const votesRef = db.ref('event-submissions').child(eventId);
      const membersRef = db.ref(EVENT_MEMBERS_REF).child(eventId);
      yield call([ref, ref.set], null);
      yield call([votesRef, votesRef.set], null);
      yield call([membersRef, membersRef.set], null);
    }
    yield put(getEvents());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchDeleteEvent() {
  yield takeEvery(DELETE_EVENT, deleteEventListener);
}

const GET_EVENTS = 'GET_EVENTS';
export const getEvents = createAction(GET_EVENTS);
function* getEventsListener(action) {
  try {
    yield put(isLoading(action.type));

    const getAllEvents = functions.httpsCallable('getAllEvents');
    const response = yield call(getAllEvents);
    yield put(saveEvents(response.data.events));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetEvents() {
  yield takeEvery(GET_EVENTS, getEventsListener);
}

export const GET_EVENT = 'GET_EVENT';
export const getEvent = createAction(GET_EVENT);
function* getEventListener(action) {
  try {
    yield put(isLoading(action.type));

    const getSingleEvent = functions.httpsCallable('getEvent');
    const eventId = action.payload;
    const response = yield call(getSingleEvent, { eventId });
    yield put(saveEvent(response.data.event));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetEvent() {
  yield takeEvery(GET_EVENT, getEventListener);
}

/**
 * Submits the final user votes
 */
const CAST_USER_VOTES = 'CAST_USER_VOTES';
export const castUserVotes = createAction(CAST_USER_VOTES);
function* castUserVotesListener(action) {
  try {
    yield put(isLoading(action.type));
    const state = yield select();
    const { start } = state.events.event;
    const date = start.split('T')[0];
    const { votes, notes } = action.payload;

    const castUserVotesFunction = functions.httpsCallable('castUserVotes');

    yield call(castUserVotesFunction, { eventId: date, note: notes, votes });
    alert('Submitted');

    yield put(getEvent(date));
  } catch (error) {
    alert('Unable to submit your selections, please see the front desk');
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchCastUserVotes() {
  yield takeEvery(CAST_USER_VOTES, castUserVotesListener);
}

const JOIN_EVENT = 'events/JOIN_EVENTS';
export interface JoinEventAction extends Action<typeof JOIN_EVENT> {
  readonly payload: {
    readonly eventId: string;
  };
}

export const joinEvent = (eventId: string): JoinEventAction => ({
  payload: {
    eventId
  },
  type: JOIN_EVENT
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* joinEventListener(action: JoinEventAction) {
  try {
    yield put(isLoading(action.type));

    const { eventId } = action.payload;
    const joinEventFunction = functions.httpsCallable('joinEvent');
    yield call(joinEventFunction, { eventId });
    yield put(getEvents());

    yield put(replace(routeBuilder.get('event', { eventId })));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* watchJoinEvent() {
  yield takeEvery(JOIN_EVENT, joinEventListener);
}

const UPDATE_EVENT_MEMBER = 'UPDATE_EVENT_MEMBER';
export interface UpdateEventMemberAction extends Action<typeof UPDATE_EVENT_MEMBER> {
  readonly payload: {
    readonly eventId: string;
    readonly uid: string;
    readonly attending: boolean;
  };
}

export const updateEventMember = (data: UpdateEventMemberAction['payload']): UpdateEventMemberAction => ({
  payload: data,
  type: UPDATE_EVENT_MEMBER
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* updateEventMemberListener(action: UpdateEventMemberAction) {
  try {
    yield put(isLoading(action.type));
    const { eventId, uid, attending } = action.payload;
    const ref = db
      .ref(EVENT_MEMBERS_REF)
      .child(eventId)
      .child(uid);
    if (attending) {
      yield call([ref, ref.set], attending ? Date.now() : null);
    } else {
      yield call([ref, ref.remove]);
    }

    yield put(getEvent(eventId));
    yield put(getEvents());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* watchUpdateEventMember() {
  yield takeEvery(UPDATE_EVENT_MEMBER, updateEventMemberListener);
}

const MARK_EXTENDED_DATE = 'MARK_EXTENDED_DATE';
export interface MarkExtendedDateAction extends Action<typeof MARK_EXTENDED_DATE> {
  readonly payload: {
    readonly eventId: ModelID;
    readonly date: string;
    readonly count: number;
  };
}
export const markExtendedDate = (data: MarkExtendedDateAction['payload']): MarkExtendedDateAction => ({
  payload: data,
  type: MARK_EXTENDED_DATE
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* markExtendedDateListener(action: MarkExtendedDateAction) {
  try {
    yield put(isLoading(action.type));
    const { eventId, date, count } = action.payload;
    if (eventId && date) {
      const markDateAsExtended = functions.httpsCallable('markDateAsExtended');

      yield call(markDateAsExtended, { date, eventId, limit: count });
      yield put(getEvent(eventId));
    } else {
      console.log('Failed attempt');
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* watchMarkExtendedDate() {
  yield takeEvery(MARK_EXTENDED_DATE, markExtendedDateListener);
}
