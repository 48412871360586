import * as _ from 'lodash';
import { Action } from 'redux';
import { createAction } from 'redux-actions';
import { takeEvery, put, call } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import { doneLoading, isLoading, persistConfiguration } from '../reducers/system';

// import api from '../api';
import { functions, db } from 'store/firebase';

const SET_ACTIVE_EVENT = 'system/SET_ACTIVE_EVENT';
export interface SetActiveEventAction extends Action<typeof SET_ACTIVE_EVENT> {
  readonly payload: {
    readonly event: string;
  };
}
export const setActiveEvent = (event: string) => ({
  payload: {
    event
  },
  type: SET_ACTIVE_EVENT
});
function* callSetActiveEvent(action: SetActiveEventAction) {
  try {
    yield put(isLoading(action.type));

    const activeEventRef = db.ref('configuration').child('activeEvent');

    yield call([activeEventRef, activeEventRef.set], action.payload.event);
    yield put(getSystemConfig());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchSetActiveEvent() {
  yield takeEvery(SET_ACTIVE_EVENT, callSetActiveEvent);
}

const GET_SYSTEM_CONFIG = 'system/GET_SYSTEM_CONFIG';
export const getSystemConfig = createAction(GET_SYSTEM_CONFIG);
function* getSystemConfigListener(action) {
  try {
    yield put(isLoading(action.type));
    const getSystemConfigFunction = functions.httpsCallable('getSystemConfig');
    const response = yield call(getSystemConfigFunction);

    yield put(persistConfiguration(response.data.config));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetSystemConfig() {
  yield takeEvery(GET_SYSTEM_CONFIG, getSystemConfigListener);
}

const UPDATE_SYSTEM_CONFIG = 'system/UPDATE_SYSTEM_CONFIG';
export const updateSystemConfig = createAction(UPDATE_SYSTEM_CONFIG);
function* updateSystemConfigListener(action) {
  try {
    yield put(isLoading(action.type));

    // yield put(persistConfiguration(response.data.config));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchUpdateSystemConfig() {
  yield takeEvery(UPDATE_SYSTEM_CONFIG, updateSystemConfigListener);
}

export function* onLocationChangeListener(action) {
  try {
    yield put(getSystemConfig());
  } catch (error) {
    console.log(error);
  }
}

export function* watchOnLocationChange() {
  yield takeEvery(LOCATION_CHANGE, onLocationChangeListener);
}

export const GET_SYSTEM_VERSION = '@system/GET_SYSTEM_VERSION';
export interface GetSystemVersionAction extends Action<typeof GET_SYSTEM_VERSION> {}
export const getSystemVersion = (): GetSystemVersionAction => ({ type: GET_SYSTEM_VERSION });
export function* getSystemVersionListener(action: GetSystemVersionAction) {
  try {
    yield put(isLoading(action.type));
    const getSystemVersionFn = functions.httpsCallable('getSystemVersion');
    const response = yield call(getSystemVersionFn);

    const version = _.get(response, 'data.version');

    if (process.env.REACT_APP_VERSION !== version && process.env.NODE_ENV === 'production') {
      alert(
        `Your app version (${process.env.REACT_APP_VERSION}) is out of date, please (force) refresh your browser to the latest version (${version}).`
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetSystemVersion() {
  yield takeEvery(GET_SYSTEM_VERSION, getSystemVersionListener);
}
