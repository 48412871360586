import React from 'react';

import { LinearProgress } from '@material-ui/core';

import { Grid } from 'components/layout/Grid';
import { Box } from 'components/layout/Box';
import Text from 'components/text/Text';

const Loading = () => {
  return (
    <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item>
        <Box bgcolor="#EEE" p={2} borderRadius="3px">
          <Text bold variant="h4" gutterBottom>
            Loading...
          </Text>
          <LinearProgress variant="indeterminate" />
        </Box>
      </Grid>
    </Grid>
  );
};

export { Loading };
