import _ from 'lodash';
import { handleActions, createAction } from 'redux-actions';
import md5 from 'md5';

export interface UserState {
  readonly token?: string;
  readonly auth?: UserAuth;
  readonly profile?: User;
  readonly current?: any;
  readonly users: ReadonlyArray<User>;
}

const defaultState: UserState = {
  auth: undefined,
  current: undefined,
  profile: undefined,
  token: undefined,
  users: []
};

export const saveUsers = createAction('SAVE_USERS');
export const saveUserToken = createAction('SAVE_USER_TOKEN');
export const saveProfile = createAction('SAVE_PROFILE');
export const saveUserAuth = createAction('SAVE_USER_AUTH');
export const saveEvent = createAction('SAVE_EVENT');
export const saveCurrent = createAction('SAVE_CURRENT');
export const logout = createAction('LOGOUT');

export default handleActions(
  {
    LOGOUT: () => defaultState,
    SAVE_EVENT: (state, action) => ({
      ...state,
      event: action.payload
    }),
    SAVE_PROFILE: (state, action) => ({
      ...state,
      profile: action.payload || defaultState.profile
    }),
    SAVE_USERS: (state, action) => ({
      ...state,
      users: action.payload
    }),
    SAVE_USER_AUTH: (state, action) => {
      const email = _.get(action, 'payload.email', defaultState.auth);
      if (email) {
        return {
          ...state,
          auth: {
            email,
            hash: email ? md5(action.payload.email) : defaultState.auth,
            uid: action.payload.uid
          }
        };
      }
      return state;
    },

    SAVE_USER_TOKEN: (state, action) => ({
      ...state,
      token: action.payload
    })
  },
  defaultState
);
