/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from 'lodash';
import { Action } from 'redux';
import { createAction } from 'redux-actions';
import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import { replace } from 'react-router-redux';

import { auth, getCurrentUserToken, analytics, functions, db } from '../firebase';
import { isLoading, doneLoading } from '../reducers/config';
import { saveCurrent, saveUsers, saveProfile, saveUserAuth, logout, saveUserToken } from '../reducers/user';
import { getEvent } from './events';
import { ApplicationState } from 'types/meet-the-dj/state';

export { logout };

const PROFILES_REF = 'profiles/';

export const GET_USERS = 'GET_USERS';
export const getUsers = createAction(GET_USERS);
function* getUsersListener(action) {
  try {
    yield put(isLoading(action.type));

    const getAllUsers = functions.httpsCallable('getAllUsers');
    const response = yield call(getAllUsers);
    yield put(saveUsers(response.data.users));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsersListener);
}

/**
 * Update another user's profile
 */
export const UPDATE_USER = 'UPDATE_USER';
export const updateUser = createAction(UPDATE_USER);
export function* updateUserListener(action) {
  try {
    yield put(isLoading(action.type));
    const { user, role } = action.payload;

    const updateUserFunction = functions.httpsCallable('updateUser');
    _.set(user, 'profile.roles', { [role]: true });
    yield call(updateUserFunction, {
      user: {
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        roles: user.profile.roles,
        uid: user.uid
      }
    });

    yield put(getUsers());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUserListener);
}

/**
 * Update your existing profile
 */
const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const updateProfile = createAction(UPDATE_PROFILE);
function* updateProfileListener(action) {
  try {
    yield put(isLoading(action.type));
    const state = yield select();
    const { uid } = state.user.auth;
    const profile: Partial<User> = _.get(action, 'payload', {});

    const { firstName, lastName } = profile;

    const ref = db.ref(PROFILES_REF).child(uid);
    yield call([ref, ref.update], { firstName, lastName });
    yield put(getProfile());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_PROFILE, updateProfileListener);
}

const GET_PROFILE = 'GET_PROFILE';
export const getProfile = createAction(GET_PROFILE);
function* getProfileListener(action) {
  try {
    yield put(isLoading(action.type));
    const uid = _.get(auth, 'currentUser.uid');
    if (uid) {
      const ref = db.ref('profiles').child(uid);
      const user = yield call([ref, ref.once], 'value');
      yield put(saveProfile(user.val()));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetProfile() {
  yield takeEvery(GET_PROFILE, getProfileListener);
}

const GET_USER = 'GET_USER';
export const getUser = createAction(GET_USER);
function* getUserListener(action) {
  try {
    yield put(isLoading(action.type));
    const { payload: uid } = action;

    const getSingleUser = functions.httpsCallable('getUser');
    const response = yield call(getSingleUser, { uid });
    const { user } = response.data;
    yield put(saveCurrent(user));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUserListener);
}

const MARK_DATE_FOR_DJS = 'MARK_DATE_FOR_DJS';
export const markDateForDJs = createAction(MARK_DATE_FOR_DJS);
function* markDateForDJsListener(action) {
  try {
    yield put(isLoading(action.type));
    const { date, djs, selected } = action.payload;
    yield all(
      djs.map(dj => {
        const checked = !!selected.find(d => d.uid === dj.uid);
        const ref = db
          .ref(PROFILES_REF)
          .child(dj.uid)
          .child('blockout');
        return call([ref, ref.update], { [date]: checked ? false : null });
      })
    );
    yield put(getUsers());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchmarkDateForDJs() {
  yield takeEvery(MARK_DATE_FOR_DJS, markDateForDJsListener);
}

const BLOCKOFF_DATE = 'BLOCKOFF_DATE';
export interface BlockoffDateAction extends Action<typeof BLOCKOFF_DATE> {
  readonly payload: {
    readonly date: string;
    readonly checked: boolean;
  };
}
export const blockoffDate = (data: BlockoffDateAction['payload']): BlockoffDateAction => ({
  payload: data,
  type: BLOCKOFF_DATE
});
function* blockoffDateListener(action: BlockoffDateAction) {
  try {
    yield put(isLoading(action.type));
    const state: ApplicationState = yield select();
    const { auth: userAuth } = state.user;
    const eDate = state.events.event?.start;
    if (!userAuth) {
      throw new Error('Unauthorized');
    }

    const { date, checked } = action.payload;
    const ref = db.ref(PROFILES_REF).child(userAuth?.uid);
    yield call([ref, ref.transaction], (profile: any = {}) => {
      // eslint-disable-next-line
      profile = profile ? profile : {};
      const blockout = {
        ...profile.blockout
      };
      if (profile) {
        blockout[date] = checked ? true : null;
      }
      return { ...profile, blockout };
    });
    if (eDate) {
      yield put(getEvent(eDate.split('T')[0]));
    }
    yield put(getProfile());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchBlockoffDate() {
  yield takeEvery(BLOCKOFF_DATE, blockoffDateListener);
}

export const UPDATE_ROLES = 'UPDATE_ROLES';
export const updateRoles = createAction(UPDATE_ROLES);
export function* updateRolesListener(action) {
  try {
    yield put(isLoading(action.type));
    const state = yield select();
    const roles = _.get(state, 'user.roles', {});
    if (roles.staff) {
      const { uid, role } = action.payload;
      const ref = db
        .ref(PROFILES_REF)
        .child(uid)
        .child('roles');
      yield call([ref, ref.set], { [role]: true });
    }
    yield put(getUsers());
    yield put(getProfile());
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchUpdateRoles() {
  yield takeEvery(UPDATE_ROLES, updateRolesListener);
}

const LOGIN = 'LOGIN';
export const login = createAction('LOGIN');
function* loginListener(action) {
  try {
    yield put(isLoading(action.type));
    const { email, password } = action.payload;
    const response = yield call([auth, auth.signInWithEmailAndPassword], email, password);
    const { uid, email: authenticatedEmail } = response.user;
    analytics.setUserId(uid);

    // const token = yield call(getCurrentUserToken);
    // yield put(saveUserToken(token));

    // yield put(saveUserAuth({ email: authenticatedEmail, uid }));
    // yield put(getProfile());

    // yield put(replace('/'));
  } catch (error) {
    alert('Invalid Email or Password');
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN, loginListener);
}

export const REGISTER = 'REGISTER';
export const register = createAction(REGISTER);
export function* registerListener(action) {
  try {
    yield put(isLoading(action.type));
    const { account, email, eventDate, firstName, lastName } = action.payload;
    const user = {
      email,
      password: account,
      phoneNumber: `+1${account}`,
      eventDate,
      firstName,
      lastName
    };

    const registerUser = functions.httpsCallable('registerUser');
    const response = yield call(registerUser, { user });

    const { data } = response;
    if (data.user) {
      analytics.logEvent('user_registration', data);
      yield put(login({ email: data.user.email, password: account }));
    } else {
      throw new Error('No user in response');
    }
  } catch (error) {
    const { config, message, response } = error;

    if (config && response) {
      analytics.logEvent('registration_error', {
        data: config.data,
        response: response.data
      });
    } else {
      analytics.logEvent('registration_error', {
        message
      });
    }
    window.alert(
      'We are currently unable to register your account. Please give our office a call at (877) 423-6481 to further assist you.'
    );
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchRegister() {
  yield takeEvery(REGISTER, registerListener);
}

export const LOGOUT = 'LOGOUT';
function* logoutListener(action) {
  try {
    yield put(isLoading(action.type));
    yield call([auth, auth.signOut]);
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}
export function* watchLogout() {
  yield takeEvery(LOGOUT, logoutListener);
}

const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const updateUserPassword = createAction(UPDATE_USER_PASSWORD);
function* updateUserPasswordListener(action) {
  try {
    yield put(isLoading(action.type));
    // const { payload: user } = action;
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchUpdateUserPassword() {
  yield takeEvery(UPDATE_USER_PASSWORD, updateUserPasswordListener);
}

const REFRESH_USER_TOKEN = 'REFRESH_USER_TOKEN';
export const refreshUserToken = createAction(REFRESH_USER_TOKEN);
function* refreshUserTokenListener(action) {
  try {
    yield put(isLoading(action.type));
    const token = yield call(getCurrentUserToken);
    yield put(saveUserToken(token));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchRefreshUserToken() {
  yield takeEvery(REFRESH_USER_TOKEN, refreshUserTokenListener);
}

const DJ_CONFIRM_BLOCKOUT = 'user/DJ_CONFIRM_BLOCKOUT';
export const confirmBlockoutSchedule = createAction(DJ_CONFIRM_BLOCKOUT);
function* confirmBlockoutScheduleListener(action) {
  try {
    yield put(isLoading(action.type));
    const markDJScheduleAsConfirmedFunction = functions.httpsCallable('markDJScheduleAsConfirmed');
    const response = yield call(markDJScheduleAsConfirmedFunction, {});

    if (response.data.status === 'OK') {
      alert('Schedule Confirmed');
    }
    yield put(getProfile());
  } catch (error) {
    console.log(error);
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchConfirmBlockoutSchedule() {
  yield takeEvery(DJ_CONFIRM_BLOCKOUT, confirmBlockoutScheduleListener);
}

const SET_USER_STATUS = 'user/SET_USER_STATUS';
export interface SetUserStatusAction extends Action<typeof SET_USER_STATUS> {
  readonly payload: {
    readonly disabled: boolean;
    readonly user: User;
  };
}
export const setUserStatus = (user: User, disabled: boolean) => ({
  payload: {
    disabled,
    user
  },
  type: SET_USER_STATUS
});
function* setUserStatusListener(action: SetUserStatusAction) {
  try {
    yield put(isLoading(action.type));

    const { disabled, user } = action.payload;
    const updateUserDisabledFn = functions.httpsCallable('updateUserDisabled');
    yield call(updateUserDisabledFn, {
      disabled,
      uid: user.uid
    });
    yield put(getUsers());

    if (disabled) {
      alert('User Archived');
    } else {
      alert('User Activated');
    }
  } catch (error) {
    console.log(error);
    analytics.logEvent(action.type, {
      message: error.message
    });
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchSetUserStatus() {
  yield takeEvery(SET_USER_STATUS, setUserStatusListener);
}

export const DELETE_USER = 'user/DELETE_USER';
export interface DeleteUserAction extends Action<typeof DELETE_USER> {
  readonly payload: {
    user: User;
  };
}
export const deleteUser = (user: User): DeleteUserAction => ({
  payload: {
    user
  },
  type: DELETE_USER
});

function* deleteUserListener(action: DeleteUserAction) {
  try {
    yield put(isLoading(action.type));
    const { user } = action.payload;
    const deleteUserFn = functions.httpsCallable('deleteUser');
    yield call(deleteUserFn, { userId: user.uid });
    alert('User has been deleted');
    yield put(getUsers());
  } catch (error) {
    console.log(error);
    analytics.logEvent(action.type, {
      message: error.message
    });
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserListener);
}

export const CONFIRM_DJ_SCHEDULE = 'user/CONFIRM_DJ_SCHEDULE';
export interface ConfirmDJScheduleAction extends Action<typeof CONFIRM_DJ_SCHEDULE> {
  readonly payload: {
    readonly user: User;
  };
}
export const confirmDJSchedule = (user: User) => ({
  payload: {
    user
  },
  type: CONFIRM_DJ_SCHEDULE
});

function* confirmDJScheduleListener(action: ConfirmDJScheduleAction) {
  try {
    yield put(isLoading(action.type));

    const confirmScheduleFn = functions.httpsCallable('confirmDJSchedule');
    yield call(confirmScheduleFn, { uid: action.payload.user.uid });

    window.alert('DJ schedule is confirmed');
  } catch (error) {
    window.alert('There was an error confirming the schedule:' + error.message);
    console.log(error);
    analytics.logEvent(action.type, {
      message: error.message
    });
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchConfirmDJSchedule() {
  yield takeEvery(CONFIRM_DJ_SCHEDULE, confirmDJScheduleListener);
}

export const BLACKLIST_DJ = 'user/BLACKLIST_DJ';
export interface BlacklistDJAction extends Action<typeof BLACKLIST_DJ> {
  readonly meta: {
    onComplete?: () => void;
  };
  readonly payload: {
    readonly memberId: string;
    readonly djId: string;
    readonly blacklist: boolean;
  };
}

export const blackListDJ = (payload: BlacklistDJAction['payload'], onComplete?: () => void) => ({
  meta: {
    onComplete
  },
  payload,
  type: BLACKLIST_DJ
});

function* blacklistDJListener(action: BlacklistDJAction) {
  try {
    const { blacklist, djId, memberId } = action.payload;
    yield put(isLoading(action.type));

    const blacklistDJFn = functions.httpsCallable('blacklistDJFromClient');

    const response = yield call(blacklistDJFn, { blacklist, djId, memberId });
    console.log('Blacklist response', response.data);
    yield put(getUsers());
  } catch (error) {
    console.log(error);
    analytics.logEvent(action.type, { message: error.message });
    alert('Unable to blacklist this DJ');
  } finally {
    yield put(doneLoading(action.type));
  }
}

export function* watchBlacklistDJ() {
  yield takeEvery(BLACKLIST_DJ, blacklistDJListener);
}
