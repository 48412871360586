import * as Color from 'color';

import blue from '@material-ui/core/colors/blue';
import indigo from '@material-ui/core/colors/indigo';

export const darken = (colorString: string, value: number = 0): string => {
  const color = Color.rgb(colorString);
  return color.darken(value).toString();
};

export const lighten = (colorString: string, value: number = 0): string => {
  const color = Color.rgb(colorString);
  return color.lighten(value).toString();
};

interface NamedColor {
  readonly black: string;
  readonly cerulean: string;
  readonly cobalt: string;
  readonly friar: string;
  readonly fruitSalad: string;
  readonly geraldine: string;
  readonly geyser: string;
  readonly gull: string;
  readonly halfAndHalf: string;
  readonly irisBlue: string;
  readonly malibu: string;
  readonly malibuLight: string;
  readonly outerSpace: string;
  readonly pacificBlue: string;
  readonly persianRed: string;
  readonly redOrange: string;
  readonly silverChalice: string;
  readonly tory: string;
  readonly whisper: string;
  readonly white: string;
  readonly wildBlueYonder: string;
  readonly wildSand: string;
}

export interface ThemeColor extends NamedColor {
  readonly primary: string;
  readonly primaryLight: string;
  readonly primaryDark: string;
  readonly secondary: string;
  readonly secondaryLight: string;
  readonly secondaryMedium: string;
  readonly secondaryDark: string;
  readonly inputLabel: string;
  readonly lightGrayBackground: string;
  readonly textLightGray: string;
  readonly success: string;
  readonly errorLight: string;
  readonly error: string;
  readonly errorDark: string;
  readonly warning: string;
}

export const namedColors: NamedColor = {
  black: '#000',
  cerulean: '#007a9b',
  cobalt: '#0d47a1',
  friar: '#818181',
  fruitSalad: '#4caf50',
  geraldine: '#e57373',
  geyser: '#CFD8DC',
  gull: '#90a4ae',
  halfAndHalf: '#FFFFDD',
  irisBlue: '#00addd',
  malibu: '#90caf9',
  malibuLight: lighten('#90caf9', 0.1),
  outerSpace: '#263238',
  pacificBlue: '#0096bf',
  persianRed: '#d32f2f',
  redOrange: '#f44336',
  silverChalice: '#AAA',
  tory: '#303f9f',
  whisper: '#EEE',
  white: '#FFF',
  wildBlueYonder: '#7986cb',
  wildSand: '#F4F4F4'
};

const Colors: ThemeColor = {
  error: namedColors.redOrange,
  errorDark: namedColors.persianRed,
  errorLight: namedColors.geraldine,
  inputLabel: namedColors.friar, // v1
  lightGrayBackground: namedColors.wildSand, // v1
  primary: indigo['800'],
  primaryDark: indigo['900'],
  primaryLight: indigo['400'],
  secondary: blue['400'],
  secondaryDark: blue['600'],
  secondaryLight: blue['200'],
  secondaryMedium: blue['500'],
  success: namedColors.fruitSalad,
  textLightGray: namedColors.silverChalice, // v1
  warning: namedColors.geraldine,
  ...namedColors
};

export type ColorThemeVariant =
  | 'primaryLight'
  | 'secondaryLight'
  | 'successLight'
  | 'warningLight'
  | 'errorLight'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'infoLight'
  | 'success'
  | 'warning'
  | 'error'
  | 'default'
  | 'white'
  | 'mediumGray'
  | 'lightGray'
  | 'disabled';

export default Colors;
