import {
  watchUpdateProfile,
  watchGetProfile,
  watchGetUser,
  watchGetUsers,
  watchLogin,
  watchLogout,
  watchBlockoffDate,
  watchmarkDateForDJs,
  watchRegister,
  watchUpdateUser,
  watchUpdateUserPassword,
  watchRefreshUserToken,
  watchConfirmBlockoutSchedule,
  watchSetUserStatus,
  watchDeleteUser,
  watchConfirmDJSchedule,
  watchBlacklistDJ
} from './user';

import {
  watchGetEvents,
  watchGetEvent,
  watchCastUserVotes,
  watchUpdateEventMember,
  watchMarkExtendedDate,
  watchCreateEvent,
  watchDeleteEvent,
  watchJoinEvent
} from './events';
import {
  watchUpdateSystemConfig,
  watchGetSystemConfig,
  watchOnLocationChange,
  watchGetSystemVersion,
  watchSetActiveEvent
} from './system';
import { watchGetAccounts, watchSaveAccountId } from './config';
import { watchGetDJAvailabilityReport } from './reports';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootSaga = (middleware: any): void => {
  [
    watchUpdateProfile,
    watchGetProfile,
    watchLogin,
    watchLogout,
    watchGetUsers,
    watchGetUser,

    watchCastUserVotes,
    watchBlockoffDate,

    watchCreateEvent,
    watchDeleteEvent,
    watchGetEvent,
    watchGetEvents,
    watchmarkDateForDJs,
    watchUpdateUser,
    watchUpdateEventMember,
    watchUpdateUserPassword,
    watchRegister,
    watchRefreshUserToken,
    watchConfirmBlockoutSchedule,
    watchMarkExtendedDate,
    watchConfirmDJSchedule,

    watchSetActiveEvent,
    watchUpdateSystemConfig,
    watchGetSystemConfig,
    watchOnLocationChange,
    watchGetAccounts,
    watchSaveAccountId,
    watchSetUserStatus,
    watchDeleteUser,
    watchJoinEvent,
    watchGetSystemVersion,
    watchBlacklistDJ,

    watchGetDJAvailabilityReport
  ].forEach(saga => middleware.run(saga));
};

export default rootSaga;
