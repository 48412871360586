import { handleActions, createAction } from 'redux-actions';
import { Action, Reducer } from 'redux';
import { LOGOUT } from 'store/sagas/user';

export interface ConfigState {
  readonly isLoading: {
    readonly [type: string]: boolean | undefined;
  };
  readonly accounts: {
    readonly [account: string]: string;
  };
}
const defaultState = {
  accounts: {},
  isLoading: {}
};

export const IS_LOADING = 'config/IS_LOADING';
export interface IsLoadingAction extends Action<typeof IS_LOADING> {
  readonly payload: string;
}
export const isLoading = (payload: string): IsLoadingAction => ({ payload, type: IS_LOADING });

export const DONE_LOADING = 'config/DONE_LOADING';
export const doneLoading = createAction(DONE_LOADING);

export const PERSIST_ACCOUNTS = 'config/PERSIST_ACCOUNTS';
export const persistAccounts = createAction(PERSIST_ACCOUNTS);

const reducer: Reducer<ConfigState, any> = (state: ConfigState = defaultState, action): ConfigState => {
  switch (action.type) {
    case DONE_LOADING: {
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            [action.payload]: undefined
          }
        };
      }
      return state;
    }
    case IS_LOADING: {
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            [action.payload]: true
          }
        };
      }
      return state;
    }
    case PERSIST_ACCOUNTS: {
      return {
        ...state,
        accounts: action.payload
      };
    }
    case LOGOUT:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
