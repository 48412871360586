import { Reducer, Action } from 'redux';

export interface ReportState {
  readonly djAvailabilityReport?: any;
}
const defaultState: ReportState = {
  djAvailabilityReport: undefined
};

const PERSIST_DJ_AVAILABILITY = 'report/PERSIST_DJ_AVAILABILITY';
interface PersistDJAvailabilityAction extends Action<typeof PERSIST_DJ_AVAILABILITY> {
  readonly payload: {
    readonly data: any;
  }
}
export const persistDJAvailability = (data: any): PersistDJAvailabilityAction => ({
  payload: {
    data
  },
  type: PERSIST_DJ_AVAILABILITY
});

export type ReportAction = PersistDJAvailabilityAction;

const reducer: Reducer<ReportState, ReportAction> = (state = defaultState, action) => {
  switch (action.type) {
    case PERSIST_DJ_AVAILABILITY:
      return {
        ...state,
        djAvailabilityReport: action.payload.data
      };
    default:
      return state;
  }
};

export default reducer;