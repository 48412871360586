import { routerReducer } from 'react-router-redux';
import localForage from 'localforage';
import { persistCombineReducers } from 'redux-persist';
import Config from './config';
import User from './user';
import Events from './events';
import System from './system';
import Report from './report';


const reducer = {
  config: Config,
  events: Events,
  report: Report,
  router: routerReducer,
  system: System,
  user: User,
};

const combinedReducers = persistCombineReducers({ key: 'primary', storage: localForage, debug: true }, reducer as any);

export default combinedReducers;
