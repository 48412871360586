import React from 'react';
import { Route, Router as ReactRouter, Redirect, Switch } from 'react-router-dom';
import { History } from 'history';
import { AuthenticatedPage } from 'containers/AuthenticatedPage';
import routeBuilder from './route.service';

const UsersPage = React.lazy(() => import('../containers/UsersPage'));
const EventsPage = React.lazy(() => import('../containers/EventsPage'));
const EventPage = React.lazy(() => import('../containers/event/ConnectedEventPage'));
const EventReviewPage = React.lazy(() => import('../containers/event/ConnectedEventReviewPage'));
const LoginPage = React.lazy(() => import('../containers/LoginPage'));
const DJLoginPage = React.lazy(() => import('../containers/DJLoginPage'));
const RegisterPage = React.lazy(() => import('../containers/RegisterPage'));
const ProfilePage = React.lazy(() => import('../containers/ProfilePage'));
const SchedulingPage = React.lazy(() => import('../containers/DJSchedulingPage'));
const DashboardPage = React.lazy(() => import('../containers/DashboardPage'));
const RegistrationPage = React.lazy(() => import('../containers/ConnectedRegistrationPage'));
const FAQPage = React.lazy(() => import('../containers/FAQPage'));
const ProtectedRoute = React.lazy(() => import('./ProtectedRoute'));

// const VotePage = React.lazy(() => import('../pages/client/VotePage'));

export interface RouterProps {
  readonly history: History<any>;
}

const Router: React.FC<RouterProps> = ({ history }) => {
  return (
    <ReactRouter history={history}>
      <Switch>
        <Route exact path="/test" render={props => <AuthenticatedPage {...props} />} />

        {/* Unauthenticated Routes */}
        <Route exact path={routeBuilder.get('login')} component={LoginPage} />
        <Route exact path={routeBuilder.get('register')} component={RegisterPage} />
        <Route exact path="/dj-login" component={DJLoginPage} />

        {/* Any Auth Route */}
        <ProtectedRoute authenticated exact path={routeBuilder.get('userProfile')} component={ProfilePage} />
        {/* Staff Routes */}
        <ProtectedRoute
          authenticated
          allowedRoles={['staff', 'admin']}
          exact
          path={routeBuilder.get('users')}
          component={UsersPage}
        />
        <ProtectedRoute
          authenticated
          allowedRoles={['staff', 'admin']}
          exact
          path={routeBuilder.get('eventReview')}
          component={EventReviewPage}
        />
        <ProtectedRoute
          authenticated
          allowedRoles={['staff', 'admin']}
          exact
          path={routeBuilder.get('userSchedule')}
          component={SchedulingPage}
        />
        <ProtectedRoute
          authenticated
          allowedRoles={['staff', 'admin']}
          exact
          path={routeBuilder.get('userRegistration')}
          component={RegistrationPage}
        />

        {/* Client Routes */}
        <ProtectedRoute
          authenticated
          allowedRoles={['client', 'client_locked', 'staff', 'admin']}
          exact
          path={routeBuilder.get('event')}
          component={EventPage}
        />

        <ProtectedRoute
          authenticated
          allowedRoles={['client', 'client_locked', 'staff', 'admin']}
          exact
          path={routeBuilder.get('events')}
          component={EventsPage}
        />

        <ProtectedRoute
          authenticated
          allowedRoles={['staff', 'admin', 'dj']}
          exact
          path={routeBuilder.get('faq')}
          component={FAQPage}
        />
        <ProtectedRoute
          authenticated
          unauthorizedRedirect={routeBuilder.get('login')}
          exact
          path={routeBuilder.get('home')}
          component={DashboardPage}
        />

        <Route component={() => <Redirect to={routeBuilder.get('events')} />} />
      </Switch>
    </ReactRouter>
  );
};

export default Router;
